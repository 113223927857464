var _defineProperty = require("/Users/fadiazitouni/Documents/prod_front/mspot-ephj-2020/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _CATEGORIES_MAPPING;

var DATA_TYPE_AIRCRAFTS = 'aircrafts';
var DATA_TYPE_AIRCRAFT_CATEGORIES = 'aircraft_categories';
var DATA_TYPE_ANIMATIONS = 'animations';
var DATA_TYPE_BRANDS = 'brands';
var DATA_TYPE_BRAND_CATEGORIES = 'brand_categories';
var DATA_TYPE_COUNTRIES = 'countries';
var DATA_TYPE_EVENTS = 'events';
var DATA_TYPE_EVENT_CATEGORIES = 'event_categories';
var DATA_TYPE_EXHIBITORS = 'exhibitors';
var DATA_TYPE_EXHIBITOR_CATEGORIES = 'exhibitor_categories';
var DATA_TYPE_FLIGHTS_SCHEDULE = 'flights_schedule';
var DATA_TYPE_ICONS = 'icons';
var DATA_TYPE_ICON_CATEGORIES = 'icon_categories';
var DATA_TYPE_ICONS_CATEGORIES = 'icons_categories';
var DATA_TYPE_INFOS = 'infos';
var DATA_TYPE_KLIPSO_LEADS_CONTACTS = 'kleads_contacts';
var DATA_TYPE_NEWPRODUCTS = 'newproducts';
var DATA_TYPE_NEWPRODUCT_CATEGORIES = 'newproduct_categories';
var DATA_TYPE_PLACES = 'places';
var DATA_TYPE_PLACE_MEMBERS = 'place_members';
var DATA_TYPE_PMRS = 'pmrs';
var DATA_TYPE_RESTAURANTS = 'restaurants';
var DATA_TYPE_RESTAURANT_CATEGORIES = 'restaurant_categories';
var DATA_TYPE_MONUMENTS = 'monuments';
var DATA_TYPE_SERVICES = 'services';
var DATA_TYPE_SERVICE_CATEGORIES = 'service_categories';
var DATA_TYPE_SPEAKERS = 'speakers';
var DATA_TYPE_SPEAKER_CATEGORIES = 'speaker_categories';
var DATA_TYPE_FAVORITE_POSITIONS = 'favoritepositions';
var DATA_TYPE_DOCUNITS = 'docunits';
var DATA_TYPE_DOCUNIT_CATEGORIES = 'docunit_categories';
var DATA_TYPE_DOCUMENTS = 'documents';
var DATA_TYPE_PAPER_CATEGORIES = 'paper_categories';
var DATA_TYPE_PAPERS = 'papers';
var DATA_TYPE_PARTICIPANTS = 'participants';
var DATA_TYPE_CONTACTED_PARTICIPANTS = 'contacted_participants';
var DATA_TYPE_CONTACTS = 'contacts';
var DATA_TYPE_MEETING_CONTACTS = 'meetingContacts';
/** **************************************************** */
// Data update permissions

var DATA_AUTO_UPDATE = true;
var AUTO_RELOAD_MAP_AT_UPDATE = true;
/** **************************************************** */

/** **************************************************** */

/** ********* TO UPDATE FOR EVERY PROJECT ************** */

/** **************************************************** */

/**
 * Used data types on this project
 */

var VALID_DATA_TYPES = [// DATA_TYPE_AIRCRAFTS,
// DATA_TYPE_AIRCRAFT_CATEGORIES,
// DATA_TYPE_ANIMATIONS,
// DATA_TYPE_BRANDS,
// DATA_TYPE_BRAND_CATEGORIES,
DATA_TYPE_COUNTRIES, DATA_TYPE_EVENTS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES, // DATA_TYPE_FLIGHTS_SCHEDULE,
DATA_TYPE_ICONS, // DATA_TYPE_INFOS,
DATA_TYPE_KLIPSO_LEADS_CONTACTS, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_PLACES, // DATA_TYPE_PMRS,
// DATA_TYPE_RESTAURANTS,
// DATA_TYPE_RESTAURANT_CATEGORIES,
DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SPEAKERS, // DATA_TYPE_SPEAKER_CATEGORIES,
DATA_TYPE_FAVORITE_POSITIONS, // MobiGeo favorite positions
// DATA_TYPE_DOCUNITS,
// DATA_TYPE_DOCUNIT_CATEGORIES,
// DATA_TYPE_DOCUMENTS
// DATA_TYPE_PAPERS,
// DATA_TYPE_PAPER_CATEGORIES,
DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTED_PARTICIPANTS, DATA_TYPE_MEETING_CONTACTS, DATA_TYPE_CONTACTS];
/**
 * Tables triggering a MobiGeo restart when updated
 */

var MOBIGEO_USED_TABLES = [// DATA_TYPE_AIRCRAFTS,
// DATA_TYPE_BRANDS,
DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_ICONS, DATA_TYPE_PLACES, // DATA_TYPE_RESTAURANTS,
DATA_TYPE_SERVICES // DATA_TYPE_ANIMATIONS
// also: gtw_paths, paths, polygons, but they are in graph.json
// which is an asset, updated by complete overriding.
];

function getSpeakerTitle(speaker) {
  var value = '';

  if (speaker) {
    if (speaker.civility) {
      if (speaker.civility !== 'Monsieur' && speaker.civility !== 'Madame') value += "".concat(speaker.civility, " ");
    }

    if (speaker.lastname) {
      value += "".concat(speaker.lastname);
    }

    if (speaker.firstname) {
      value += ", ".concat(speaker.firstname);
    }
  }

  return value;
}

function getSpeakerSubtitle(speaker) {
  var value = [];

  if (speaker) {
    if (speaker.organisation) {
      value.push(speaker.organisation);

      if (speaker.position) {
        value.push(speaker.position);
      }
    } else if (speaker.country) {
      value.push(speaker.country);
    } else if (speaker.lump && speaker.lump.type) {
      value.push(speaker.lump.type);
    }
  }

  return value.join('<br/>');
}
/**
 * Define here which exhibitor field must be used when calling Taiga web services
 * (e.g retrieving participants of an exhibitor by calling WS)
 *
 * @param  {object} exh
 * @return {string}
 */


function getExhibitorGaiaId(exh) {
  if (!exh) {
    return null;
  } // return exh.original_id; // when exhibitor data is provided by taiga/gaia


  return exh.lump.GaiaId; // when exhibitor data is provided by another source
}
/**
 * Define here which participant field must be used to be able
 * to find an exhibitor in our local data (db json - Query.find(exhId, DATA_TYPE_EXHIBITORS))
 *
 * @param  {object} participant
 * @return {string}
 */


function getParticipantExhibitorOriginalId(participant) {
  if (!participant) {
    return null;
  } // return participant.exhibitor ? participant.exhibitor.exhibitorId : null; // when exhibitor data is provided by taiga/gaia


  return participant.exhibitor ? participant.exhibitor.exhibitorId.toLowerCase() : null; // when exhibitor data is provided by another source
}
/**
 * Define here which participant field must be used to be able
 * to find an exhibitor in using Gaia/Taiga web services
 *
 * @param  {object} participant
 * @return {string}
 */


function getParticipantExhibitorGaiaId(participant) {
  if (!participant) {
    return null;
  }

  console.log(participant.exhibitor);
  return participant.exhibitor ? participant.exhibitor.exhibitorId : null; // when exhibitor data is provided by taiga/gaia
}
/** **************************************************** */

/** ***************** UPDATE END *********************** */

/** **************************************************** */

/**
 * NB: `CATEGORIES_DATA_TYPE` and `NON_CATEGORIES_DATA_TYPE` are used to
 * define lists displayed in app as WHOLE PAGES. @see ListPage, listPageReducer
 * @type {Array}
 */


var CATEGORIES_DATA_TYPE = [DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SPEAKER_CATEGORIES, DATA_TYPE_DOCUNIT_CATEGORIES, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_DOCUMENTS];
var NON_CATEGORIES_DATA_TYPE = [DATA_TYPE_ANIMATIONS, DATA_TYPE_AIRCRAFTS, DATA_TYPE_BRANDS, DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_FLIGHTS_SCHEDULE, DATA_TYPE_INFOS, DATA_TYPE_KLIPSO_LEADS_CONTACTS, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_PMRS, DATA_TYPE_RESTAURANTS, DATA_TYPE_MONUMENTS, DATA_TYPE_SERVICES, DATA_TYPE_SPEAKERS, DATA_TYPE_FAVORITE_POSITIONS, DATA_TYPE_DOCUNITS, DATA_TYPE_PAPERS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTED_PARTICIPANTS, DATA_TYPE_MEETING_CONTACTS, DATA_TYPE_CONTACTS];
var CATEGORIES_MAPPING = (_CATEGORIES_MAPPING = {}, _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_AIRCRAFTS), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_BRANDS), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EVENTS), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_NEWPRODUCTS), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_RESTAURANTS), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SERVICES), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_SPEAKER_CATEGORIES, DATA_TYPE_SPEAKERS), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_DOCUNIT_CATEGORIES, DATA_TYPE_DOCUNITS), _defineProperty(_CATEGORIES_MAPPING, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_PAPERS), _CATEGORIES_MAPPING);
module.exports = {
  DATA_TYPE_AIRCRAFTS: DATA_TYPE_AIRCRAFTS,
  DATA_TYPE_AIRCRAFT_CATEGORIES: DATA_TYPE_AIRCRAFT_CATEGORIES,
  DATA_TYPE_ANIMATIONS: DATA_TYPE_ANIMATIONS,
  DATA_TYPE_BRANDS: DATA_TYPE_BRANDS,
  DATA_TYPE_BRAND_CATEGORIES: DATA_TYPE_BRAND_CATEGORIES,
  DATA_TYPE_COUNTRIES: DATA_TYPE_COUNTRIES,
  DATA_TYPE_EVENTS: DATA_TYPE_EVENTS,
  DATA_TYPE_EVENT_CATEGORIES: DATA_TYPE_EVENT_CATEGORIES,
  DATA_TYPE_EXHIBITORS: DATA_TYPE_EXHIBITORS,
  DATA_TYPE_EXHIBITOR_CATEGORIES: DATA_TYPE_EXHIBITOR_CATEGORIES,
  DATA_TYPE_FLIGHTS_SCHEDULE: DATA_TYPE_FLIGHTS_SCHEDULE,
  DATA_TYPE_ICONS: DATA_TYPE_ICONS,
  DATA_TYPE_ICON_CATEGORIES: DATA_TYPE_ICON_CATEGORIES,
  DATA_TYPE_ICONS_CATEGORIES: DATA_TYPE_ICONS_CATEGORIES,
  DATA_TYPE_INFOS: DATA_TYPE_INFOS,
  DATA_TYPE_KLIPSO_LEADS_CONTACTS: DATA_TYPE_KLIPSO_LEADS_CONTACTS,
  DATA_TYPE_NEWPRODUCTS: DATA_TYPE_NEWPRODUCTS,
  DATA_TYPE_NEWPRODUCT_CATEGORIES: DATA_TYPE_NEWPRODUCT_CATEGORIES,
  DATA_TYPE_PLACES: DATA_TYPE_PLACES,
  DATA_TYPE_PLACE_MEMBERS: DATA_TYPE_PLACE_MEMBERS,
  DATA_TYPE_PMRS: DATA_TYPE_PMRS,
  DATA_TYPE_RESTAURANTS: DATA_TYPE_RESTAURANTS,
  DATA_TYPE_RESTAURANT_CATEGORIES: DATA_TYPE_RESTAURANT_CATEGORIES,
  DATA_TYPE_SERVICES: DATA_TYPE_SERVICES,
  DATA_TYPE_SERVICE_CATEGORIES: DATA_TYPE_SERVICE_CATEGORIES,
  DATA_TYPE_SPEAKERS: DATA_TYPE_SPEAKERS,
  DATA_TYPE_SPEAKER_CATEGORIES: DATA_TYPE_SPEAKER_CATEGORIES,
  DATA_TYPE_DOCUNITS: DATA_TYPE_DOCUNITS,
  DATA_TYPE_DOCUNIT_CATEGORIES: DATA_TYPE_DOCUNIT_CATEGORIES,
  DATA_TYPE_DOCUMENTS: DATA_TYPE_DOCUMENTS,
  DATA_TYPE_MONUMENTS: DATA_TYPE_MONUMENTS,
  DATA_TYPE_PAPER_CATEGORIES: DATA_TYPE_PAPER_CATEGORIES,
  DATA_TYPE_PAPERS: DATA_TYPE_PAPERS,
  DATA_TYPE_PARTICIPANTS: DATA_TYPE_PARTICIPANTS,
  DATA_TYPE_CONTACTED_PARTICIPANTS: DATA_TYPE_CONTACTED_PARTICIPANTS,
  DATA_TYPE_MEETING_CONTACTS: DATA_TYPE_MEETING_CONTACTS,
  DATA_TYPE_CONTACTS: DATA_TYPE_CONTACTS,
  DATA_TYPE_FAVORITE_POSITIONS: DATA_TYPE_FAVORITE_POSITIONS,
  CATEGORIES_DATA_TYPE: CATEGORIES_DATA_TYPE,
  NON_CATEGORIES_DATA_TYPE: NON_CATEGORIES_DATA_TYPE,
  CATEGORIES_MAPPING: CATEGORIES_MAPPING,
  VALID_DATA_TYPES: VALID_DATA_TYPES,
  MOBIGEO_USED_TABLES: MOBIGEO_USED_TABLES,
  DATA_AUTO_UPDATE: DATA_AUTO_UPDATE,
  AUTO_RELOAD_MAP_AT_UPDATE: AUTO_RELOAD_MAP_AT_UPDATE,
  getSpeakerTitle: getSpeakerTitle,
  getSpeakerSubtitle: getSpeakerSubtitle,
  getExhibitorGaiaId: getExhibitorGaiaId,
  getParticipantExhibitorOriginalId: getParticipantExhibitorOriginalId,
  getParticipantExhibitorGaiaId: getParticipantExhibitorGaiaId
};