import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_COUNTRIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES } from 'data/config/dataConfig';
import config from 'data/config/config';
import { LIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY, LOGIN_PAGE_KEY, USER_DATA_PAGE_KEY, MAP_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY, INBOX_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import AdBanner from 'src/components/ad-banner/AdBanner';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
import { getCurrent } from 'src/core/Lang';
import { getUserData } from 'src/core/user-data/UserDataService';
import { isSessionValid } from 'src/core/login/LoginService';
import { getBindedActions } from 'src/store/bindedActions';
var LOG_PREF = '[homeConfig] ';

var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE; // NB: used for ChooseProfile page too !


export var getStyle = function getStyle() {
  return {
    background: "url(".concat(getUrl('files/project/home/home_background.jpg'), ") no-repeat"),
    backgroundSize: 'cover'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 2
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export var GENERAL_TILE_OPTIONS = {
  height: 0.9,
  // rowspan
  width: 1 // colspan

};
var tileBgGradient = 'linear-gradient(to bottom,rgba(252, 252, 252, 0.1), rgba(245, 245, 245, 0.9))';

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem( // Style
  {
    container: {
      style: {},
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  homebutton["label_".concat(lang)], // Action to perform on click
  function (actions) {
    var url = homebutton["link_".concat(lang)],
        target = homebutton["target"];

    if (openUrl(url, null, target, true)) {
      actions.linkClicked(url);
    }
  }, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

function getParticipantsTile(orientation) {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }

  var isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Mise-en-relation.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.miseEnRelation', // Page & props
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY, isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  }, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
}

function getEbadgeTile(orientation) {
  if (config.LOGIN.FEATURE_ENABLED !== true) {
    return null;
  }

  return createItem( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/badge.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.ebadge', // Action to perform on click
  function (actions) {
    if (!isSessionValid()) getBindedActions().navigate(LOGIN_PAGE_KEY);else {
      var extRes = getUserData().externalResources;
      var url = extRes.badge[getCurrent()] || extRes.badge;

      if (openUrl(url, null, config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {
        actions.linkClicked(url);
      }
    }
  }, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 2 : 1
  });
}

var getEventsTile = function getEventsTile(orientation) {
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Programme.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.agenda', // Page & props
  // LIST_GROUPS_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  // { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_EVENTS
    }]
  }, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

var getServicesTile = function getServicesTile(orientation) {
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Informations.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.practicalInfo', // Page & props
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_SERVICE_CATEGORIES
    }],
    hasGoToSynopticButton: false
  }, // { inputs: [{ dataType: DATA_TYPE_SERVICES }] },
  // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

var getExhibitorsTile = function getExhibitorsTile(orientation) {
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Exposants.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.exhibitors', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
    }]
  }, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

var getNewProductTile = function getNewProductTile(orientation) {
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Innovations.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.newproducts', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  }, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

var getSpeakersTile = function getSpeakersTile(orientation) {
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Intervenants.svg'), ")")
      } // className: 'home-icon-intervenants',

    }
  }, // Label (i18n)
  'home.speakers', // Page & props
  LIST_PAGE_KEY, {
    contextualTitle: '',
    inputs: [{
      dataType: DATA_TYPE_SPEAKERS
    }]
  }, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

var getSocialMediaTile = function getSocialMediaTile(orientation) {
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/SocialMedia.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.medias', // Page & props
  MEDIAS_PAGE_KEY, null, // {
  //   controlMediaPageContent: {
  //     // hideTabMediaSocial: true,
  //     mediaTabKey: 'twitter',
  //   },
  // },
  // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

var getMapTile = function getMapTile(orientation) {
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Plan.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.map', // Page & props
  MAP_PAGE_KEY, null, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

var getNotifications = function getNotifications(orientation) {
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Notifications.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.notifications', // Page & props
  INBOX_PAGE_KEY, null, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

var getVideos = function getVideos(orientation) {
  return createItemRoute( // Style
  {
    container: {
      style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem'
      },
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Youtube.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.videos', // Page & props
  MEDIAS_PAGE_KEY, {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'youtube' // to match index of social Media

    }
  }, // Tile options
  {
    height: window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 0.6 : 0.9,
    width: 1
  });
};

var getDefault = function getDefault(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    } // component: AdBanner,
    // props: {
    //   ad: true,
    //   adFiles: adConfig.adsFiles,
    // },

  }, {
    component: HomeToolbar,
    props: {// Black list of buttons just to avoid side effect on older projects
      // disabledButtons: ['inbox', 'klipsoleads', 'contactScan'],
      // => to disable an icon in toolbar you need to do it in toolbarConfig.js
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Exposants
      getExhibitorsTile(orientation), // Innovations
      getNewProductTile(orientation), // Programme
      getEventsTile(orientation), // Speakers
      // getSpeakersTile(orientation),
      // PLAN
      getMapTile(orientation), // Mise en relation
      // getParticipantsTile(orientation),
      // INFOS PRATIQUES
      getServicesTile(orientation), // Notifications
      // getNotifications(orientation),
      // Social Medias
      getSocialMediaTile(orientation), getEbadgeTile(orientation) // Videos
      // getVideos(orientation),
      // INTERACTIVITE ATELIERS
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, // {
  //   component: AdSwap,
  //   props: {
  //     adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME,
  //   },
  // },
  {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
}; // const getExhibitor = (lang, adConfig, orientation, isLoggedIn) => {
//   const hasAdButtons =
//     adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
//   return [
//     {
//       component: AdSwap,
//       props: {
//         adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER,
//       },
//     },
//     {
//       component: HomeToolbar,
//       props: {
//         // Black list of buttons just to avoid side effect on older projects
//         disabledButtons: ['inbox', 'contactScan'],
//       },
//     },
//     {
//       component: HomeTiles,
//       props: {
//         baseColumnCount: BASE_COLUMN_COUNT[orientation],
//         maxColumnSize: MAX_COLUMN_SIZE[orientation],
//         tiles: [
//           // Exposants
//           getExhibitorsTile(),
//           // Programme
//           getEventsTile(),
//           // Speakers
//           getSpeakersTile(),
//           // PLAN
//           getMapTile(),
//           // Mise en relation
//           getParticipantsTile(),
//           // INFOS PRATIQUES
//           getServicesTile(),
//           // Notifications
//           getNotifications(),
//           // Social Medias
//           getSocialMediaTile(),
//           // Videos
//           getVideos(),
//           // INTERACTIVITE ATELIERS
//           // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
//         ],
//       },
//     },
//     {
//       component: AdSwap,
//       props: {
//         adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME,
//       },
//     },
//     {
//       component: MobilespotBar,
//     },
//     {
//       component: IphonexFlexMargin,
//     },
//   ];
// };

/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */


export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  return getDefault(lang, adConfig, orientation, isLoggedIn); // switch (profile) {
  //   // PRO
  //   case 'visitor':
  //     return getDefault(lang, adConfig, orientation, isLoggedIn);
  //   // GRAND PUBLIC
  //   case 'exhibitor':
  //     return getExhibitor(lang, adConfig, orientation, isLoggedIn);
  //   default:
  //     if (!profile) {
  //       console.warn(LOG_PREF + 'No profile set yet');
  //     } else {
  //       console.error(LOG_PREF + 'Unsupported profile: ' + profile);
  //     }
  // }
}