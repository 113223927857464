export default {
  id: 'de',
  common: {
    appTitle: 'EPHJ 2021',
    colon: ' : ',
    questionMark: ' ?',
    noResult: 'Keine Ergebnisse',
    yes: 'Ja',
    no: 'Nein',
    ok: 'OK',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'schließen',
    keep: 'Behalten',
    delete: 'Löschen',
    camera: 'Kamera',
    gallery: 'Galerie',
    chooseLang: 'Sprachauswahl',
    addToFavorites: 'Zu Favoriten hinzufügen',
    addNote: 'Notiz hinzufügen',
    editNote: 'Notiz bearbeiten',
    defaultPlaceLabel: 'Karte',
    noNetworkTitle: 'Netzwerk nicht verfügbar',
    noNetwork: 'Bitte versuchen Sie es später erneut',
    loading: 'Bitte warten Sie ...',
    showAll: 'Auf der Karte anzeigen',
    noPlacesToShowOnMap: 'Keine Orte zum Anzeigen auf der Karte',
    termsOfUse: "Nutzungsbedingungen",
    information: 'Informationen',
    display: 'Anzeige',
    switch2Columns: "Zu einem Anzeigemodus wechseln, der an die Breite Ihres Bildschirms angepasst ist? (empfohlen)",
    share: 'teilen',
    goBack: 'Zurück',
    goToHome: "Zurück zur Startseite",
    goToRootCategory: "Zurück zur Startseite der Rubrik",
    login: 'Anmeldung',
    goToFavorites: 'Ihre Favoriten anzeigen',
    goToInbox: 'Nachrichten anzeigen',
    goToSearch: 'Suche nach Inhalten',
    goToFilter: 'Inhalte filtern',
    goToSynopticAgenda: 'Übersicht anzeigen',
    goBackToAgenda: 'Zurück zur Veranstaltungsliste',
    openMenu: 'Menü öffnen',
    relatedContent: 'CVerknüpfte Inhalte',
    contributions: 'Beiträge',
    feedback: 'Zurück',
    rateThisSession: 'Diese Sitzung bewerten',
    seeMore: 'mehr anzeigen ...',
    seeLess: 'weniger anzeigen …',
    accept: 'Annehmen',
    deny: 'Ablehnen',
    noData: 'Keine Daten verfügbar',
    navigationDenied: 'Sie benötigen eine Authentifizierung, um auf diese Seite zuzugreifen',
    save: 'Speichern',
    saving: 'Speicherung ...',
    saved: 'Gespeichert',
    invalidAuth: 'Ungültige oder abgelaufene Sitzung',
    updateFailed: "Speicherung fehlgeschlagen",
    error: 'Ein Fehler ist aufgetreten',
    contact: 'Kontaktanfrage',
    contacted: 'Kontaktiert',
    contactConfirmMessage: 'Eine Kontaktanfrage in Ihrem Namen wird an die E-Mail-Adresse der Person gesendet. Bei Annahme antwortet die Person Ihnen per E-Mail.',
    contactErrorTitle: 'Kontaktanfrage fehlgeschlagen',
    contactError: 'Bitte versuchen Sie es später erneut.',
    fetchError: 'Fehler beim Abrufen der Daten',
    mandatory: ' <small><i> - erforderlich</i></small>',
    mandatoryInPlaceholder: ' (erforderlich)',
    emptyReadOnlyField: 'Nicht angegeben',
    smartbannerViewButton: 'ANSEHEN',
    contactScan: 'Kontaktanalyse',
    KlipsoLeads: 'Klipso Leads'
  },
  formErrors: {
    missing: 'Fehlender Wert',
    invalid: 'Ungültiger Wert'
  },
  location: {
    feature: 'Standortbestimmung',
    yourLocation: 'Ihr Standort'
  },
  disclaimer: {
    text: 'Diese App verwendet Cookies, um anonyme Nutzungsstatistiken zu erstellen.'
  },
  share: {
    mail: 'Per E-Mail teilen',
    facebook: 'Über Facebook teilen',
    linkedin: 'Über LinkedIn teilen',
    twitter: 'Über Twitter teilen',
    positionShare: 'Standort geteilt',
    // favorite position share description
    shareFail: 'Teilen des Standorts fehlgeschlagen',
    linkedinShareDone: 'Seite über Ihr LinkedIn-Profil geteilt',
    linkedinShareFail: 'Teilen über LinkedIn fehlgeschlagen',
    facebookShareDone: 'Seite über Ihr Facebook-Profil geteilt',
    facebookShareFail: 'Teilen über Facebook fehlgeschlagen'
  },
  data: {
    brands: {
      singular: 'Marke',
      plural: 'Marken',
      title: 'Marken'
    },
    brand_categories: {
      singular: 'Markenkategorie',
      plural: 'Markenkategorie',
      title: 'Marken'
    },
    exhibitors: {
      singular: 'Aussteller',
      plural: 'Aussteller',
      title: 'Aussteller',
      searchPlaceholder: 'Aussteller'
    },
    exhibitor_categories: {
      singular: "Ausstellerkategorie",
      plural: "Ausstellerkategorien",
      title: 'Aussteller'
    },
    kleads_contacts: {
      singular: 'Kontakt',
      plural: 'Kontakte'
    },
    restaurants: {
      singular: 'Gastronomie',
      plural: 'Restaurants',
      title: 'Restaurants'
    },
    restaurant_categories: {
      singular: 'Restaurantkategorie',
      plural: 'Restaurantkategorie',
      title: 'Restaurants'
    },
    monuments: {
      singular: 'Orte',
      plural: 'Orte',
      title: 'Orte'
    },
    services: {
      singular: 'Nützliche Hinweise',
      plural: 'Nützliche Hinweise',
      title: 'Nützliche Hinweise'
    },
    service_categories: {
      singular: 'Dienstleistungskategorie',
      plural: 'Dienstleistungskategorie',
      title: 'Dienstleistungen'
    },
    aircrafts: {
      singular: 'Luftfahrzeug',
      plural: 'Luftfahrzeuge',
      title: 'Luftfahrzeuge'
    },
    aircraft_categories: {
      singular: "Luftfahrzeugkategorie",
      plural: "Luftfahrzeugkategorien",
      title: 'Luftfahrzeuge'
    },
    contacts: {
      singular: 'Kontakt',
      plural: 'Kontakte'
    },
    events: {
      singular: 'Veranstaltung',
      plural: 'Programm',
      title: 'Programm',
      subevents: 'Beiträge'
    },
    event_categories: {
      plural: 'Spezialität, Typ, Programm',
      title: 'Programm'
    },
    conferences: {
      singular: 'Konferenz',
      plural: 'Konferenzen'
    },
    speakers: {
      singular: 'Teilnehmer',
      plural: 'Teilnehmer',
      title: 'Teilnehmer',
      moderators: 'Moderatoren'
    },
    papers: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    paper_categories: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    newproducts: {
      singular: 'Innovation',
      plural: 'Innovationen',
      title: 'Innovationen'
    },
    newproduct_categories: {
      singular: "Innovation Kategorie",
      plural: "Innovation Kategorie",
      title: 'Innovationen'
    },
    animations: {
      plural: 'Animationen',
      title: 'Animationen',
      singular: 'Animation',
      go: 'Siehe Karte'
    },
    favoritepositions: {
      plural: 'Standorte',
      singular: 'Standort'
    },
    participants: {
      singular: 'Kontakt',
      plural: 'Kontakt',
      title: 'Kontakt'
    },
    contacted_participants: {
      singular: 'Meine Kontaktanfragen',
      plural: 'Meine Kontaktanfragen',
      title: 'Meine Kontaktanfragen'
    },
    meetingContacts: {
      singular: 'Meine Termine',
      plural: 'Meine Termine',
      title: 'Meine Termine'
    },
    flights: {
      singular: 'Flug',
      plural: 'Flüge'
    },
    flights_schedule: {
      locale: 'de',
      // wtf ?
      longDateFormat: 'L HH:mm:ss',
      title: 'Flugplan',
      refresh: 'Aktualisieren',
      lastFetched: 'Letztes Update',
      unknownAircraft: 'Unbekanntes Luftfahrzeug',
      notScheduledYet: 'Zeitplan folgt',
      errorWhileFetching: 'Ein Fehler ist aufgetreten',
      lastRefreshed: 'Letzte Aktualisierung : ',
      noResults: 'Die Flüge sind noch nicht geplant'
    },
    docunits: {
      singular: 'Pressefach',
      plural: 'Pressefächer'
    }
  },
  dataListDialog: {
    defaultTitle: 'todo default title'
  },
  size: {
    bytes: 'Bytes',
    kilobytes: ' ko',
    megabytes: ' mo'
  },
  update: {
    modalTitle: 'Aktualisierung',
    update_detected: 'Daten aktualisieren ?',
    cancel_update: 'Aktualisierung abbrechen',
    update_done: 'Aktualisierung abgeschlossen',
    new_version_detected: 'Neue Version gefunden',
    timeout: 'Zeitüberschreitung'
  },
  lang: {
    fr: 'Französisch',
    en: 'Englisch',
    de: 'Deutsche'
  },
  menu: {
    restaurants: 'Restaurants',
    hours: 'Zeiten',
    inbox: 'Nachrichten',
    brands: 'Marken',
    exhibitors: 'Aussteller',
    exhibitorsAndNp: 'Aussteller & produits',
    services: 'Praktische Informationen',
    speakers: 'Teilnehmer',
    map: 'Karte',
    agenda: 'Konferenzen & Podiumsgespräche',
    experiences: 'Animations',
    medias: 'Soziale Netzwerke',
    update: 'aktualisieren',
    language: 'Sprache',
    close: 'Menü schließen',
    pmr: 'PMR',
    toggleProfile: 'Profil ändern',
    aircraftsAndFlights: 'Luftfahrzeuge & Flüge',
    livewall: 'LiveWall',
    twitter: '#EPHJ',
    presskits: 'Pressefächer',
    cgu: 'AGB',
    papers: 'Abstracts',
    socialagenda: 'Sozialagenda',
    miseEnRelation: 'Vermittlung',
    userData: 'Mein Profil',
    videos: 'Videos',
    newproducts: 'Innovationen'
  },
  home: {
    title: 'Startseite',
    myVisit: 'Mein Besuch',
    agenda: 'Konferenzen & Podiumsgespräche',
    experiences: 'ANIMATIONS',
    exhibitors: 'Aussteller',
    exhibitorsAndProducts: 'Aussteller & PRODUITS',
    mapsAndGroups: 'Karte',
    map: 'KARTE',
    aircraftsAndFlights: 'AÉRONEFS & VOLS',
    pmr: 'PMR',
    practicalInfo: 'Praktische Informationen',
    medias: 'Soziale Netzwerke',
    papers: 'ABSTRACTS',
    presskits: 'CASIERS PRESSE',
    socialagenda: 'PROGRAMME SOCIAL',
    speakers: 'Animationen',
    miseEnRelation: 'Vermittlung',
    interactiviteAteliers: 'INTERAKTIVITÄT WORKSHOPS',
    notifications: 'BENACHRICHTIGUNGEN',
    videos: 'VIDEOS',
    newproducts: 'Innovationen',
    ebadge: 'IHR E-TICKET'
  },
  profile: {
    title: 'Profilauswahl',
    mainLabel: 'Wählen Sie Ihr Profil aus',
    codeInput: "Geben Sie den Zugangscode ein",
    invalidCode: "Ungültiger Zugangscode",
    visitor: 'Besucher',
    exhibitor: 'Aussteller'
  },
  exhibitor: {
    contacts: 'Kontakt',
    relatedContacts: 'Kontakt auf der Messe',
    entities: 'EntiBetreibertés',
    agentCountries: 'Ausstellende Länder',
    brands: 'Marken',
    activities: 'Aktivitäten',
    categories: 'Kategorie'
  },
  placeSearch: {
    editStart: 'Startpunkt ändern',
    setDestination: 'Nächster Abschnitt',
    itinerary: 'Route',
    start: 'Wählen Sie Ihren Startpunkt',
    destination: 'Ziel',
    poi: "POI"
  },
  aircraft: {
    pilot: 'Pilot',
    coPilot: 'Co-pilot',
    country: 'Herkunftsland',
    manufacturer: 'Hersteller',
    category: 'Kategorie',
    usage: 'Utilisation',
    wingSpan: 'Envergure',
    length: 'Länge',
    emptyWeight: 'Tonnage',
    maxCruiseSpeed: 'Max. Reisegeschwindigkeit',
    characteristics: 'Eigenschaften',
    messageNoFlightYet: 'Dieses Luftfahrzeug ist für ein Flugprogramm vorgesehen. Weitere Informationen folgen zu einem späteren Zeitpunkt'
  },
  newproducts: {
    launchDate: 'Markteinführung'
  },
  events: {
    to: 'bis',
    access: 'Zugang',
    publics: 'Öffentlich'
  },
  map: {
    title: 'Karte',
    goTo: 'Besuchen',
    shouldReload: 'Die Kartendaten wurden aktualisiert. Möchten Sie jetzt neu laden ?',
    reloadDueToUpdate: 'Karte aktualisiert …',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'Keine Route gefunden.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: "Ihr Standort wird nicht bestimmt."
    },
    bluetoothWarning: 'Aktivieren Sie Bluetooth in den Einstellungen Ihres Telefons',
    locationSettingWarning: 'Aktivieren Sie die Ortungsdienste in den Einstellungen Ihres Telefons',
    goSettingsQuestionTitle: 'Berechtigungen erforderlich',
    goSettingsQuestion: 'Möchten Sie auf die Einstellungen zugreifen, um Ihren Standort zu teilen ?'
  },
  favorites: {
    title: 'Favoriten',
    download: 'Herunterladen (Excel)',
    yourFavs: 'Ihre Favoriten',
    other: 'Sonstiges'
  },
  papers: {
    title: 'Abstracts'
  },
  agenda: {
    title: 'Programm'
  },
  notes: {
    title: 'Notiz zu',
    pageTitle: 'Meine Notizen',
    pageLinkTitle: 'Meine Notizen',
    delete: 'Notiz löschen',
    deleteAlert: 'Sind Sie sicher, dass Sie diese Notiz löschen wollen ?',
    exportButton: 'Meine Notizen exportieren',
    exportSuccess: 'Notizen per E-Mail gesendet',
    errors: {
      INVALID_CREDENTIALS: 'Zugriff verweigert',
      INVALID_SMTP_CONFIGURATION: "Beim Senden der E-Mail ist ein Fehler aufgetreten",
      DEFAULT: "Beim Senden der E-Mail ist ein Fehler aufgetreten"
    },
    exportForm: {
      fields: {
        email: {
          label: 'IHR e-mail',
          validationMessage: 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
        }
      },
      title: 'Ihre Notizen per E-Mail exportieren',
      subtitle: 'Bitte geben Sie Ihre E-Mail-Adresse ein und klicken Sie auf die Schaltfläche Senden, um Ihre Notizen zu erhalten'
    }
  },
  medias: {
    title: 'Soziale Netzwerke',
    label: 'Verfolgen Sie unsere Neuigkeiten auf'
  },
  pmr: {
    title: 'PMR'
  },
  search: {
    title: 'Suche',
    placeholderSuffix: '... (min. 2 car.)',
    submitLabel: 'Ok',
    searchFor: 'Suche nach ' //        keywords: 'Mots clés',

  },
  speaker: {
    relatedInterventions: 'Beiträge'
  },
  hours: {
    title: "Öffnungszeiten"
  },
  intersticiel: {
    closeLabel: 'Skip Ad'
  },
  promoMs: '',
  inbox: {
    title: 'Messages',
    empty: "Sie haben keine Nachricht",
    dismiss: 'Überspringen',
    confirmNotif: 'OK'
  },
  login: {
    title: 'Anmelden',
    fillTheFields: 'Bitte geben Sie Ihren Benutzernamen und Passwort ein und klicken Sie auf bestätigen',
    badLogin: 'Ihr Benutzername oder Ihr Passwort ist nicht korrekt',
    loginLabel: 'E-mail',
    loginPlaceholder: 'Bitte geben Sie Ihr e-mail ein!',
    passwordLabel: 'Passwort',
    passwordPlaceholder: 'Bitte geben Sie Ihr Passwort ein!',
    loginButton: 'Bestätigen',
    loginSuccess: 'Sie sind angemeldet',
    loginToSyncYourFavs: 'Geben Sie dieselben Anmeldedaten ein, die Sie bei der Ausweisbeantragung angegeben haben, um Ihre Favoriten zwischen der Webversion eurosatory.com und der mobilen App zu synchronisieren !',
    introductoryText: 'Ihr Anmeldename und Ihr Passwort wurden an die bei der Registrierung angegebene E-Mail-Adresse gesendet (Ihre Adresse oder die der Person, die Sie registriert hat)',
    forgottenPassword: "Passwort vergessen",
    createAccount: 'Créer un compte',
    tos: "Nutzungsbedingungen",
    profile: 'Profil',
    logout: 'Abmelden',
    noNetwork: "Sie sind nicht mit dem Internet verbunden",
    loading: 'VÜberprüfung ...'
  },
  userData: {
    title: 'Profil',
    notice: 'Ihre Berechtigungen (es kann einige Minuten dauern, bis eine Änderung wirksam wird).',
    fields: {
      title: {
        label: 'Anrede',
        placeholder: ''
      },
      civility: {
        label: 'Anrede'
      },
      firstName: {
        label: 'Vorname',
        placeholder: 'Bitte geben Sie Ihren Vornamen ein'
      },
      lastName: {
        label: 'Nachname',
        placeholder: 'Bitte geben Sie Ihren Nachnamen ein'
      },
      organizationName: {
        label: 'Unternehmen ',
        placeholder: 'Bitte geben Sie Ihre Organisation ein'
      },
      departement: {
        label: 'Abteilung',
        placeholder: ''
      },
      position: {
        label: 'Funktion',
        placeholder: 'Bitte geben Sie Ihre Funktion ein'
      },
      email: {
        label: 'Kontakt E-Mail - erforderlich',
        placeholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein'
      },
      phone: {
        label: 'Téléphone',
        placeholder: 'Bitte geben Sie Ihr Telefon ein'
      },
      visible: {
        label: 'In Kontakten sichtbar',
        placeholder: ''
      },
      contactable: {
        label: 'Contactable',
        placeholder: ''
      },
      login: {
        label: 'Anmeldename'
      }
    },
    picture: {
      title: 'Profilbild',
      selectYours: 'Wählen Sie Ihr Foto aus',
      pickError: 'Bei der Verarbeitung Ihres Fotos ist ein Fehler aufgetreten',
      confirmRemoval: 'Bestätigen Sie das Löschen Ihres Fotos ?',
      confirmSubstitution: 'Sie sind dabei, Ihr Profilbild zu ersetzen.<br>Bestätigen Sie diese Aktion ?',
      cropRequire: "Sie haben Ihr Bild nicht bestätigt"
    },
    updateSuccess: 'Speicherung abgeschlossen',
    yourProfile: 'Ihr Profil',
    yourEvents: 'Ihre Konferenzen',
    contactedParticipantsSearchPlaceholder: 'Suche nach kontaktierten Teilnehmern',
    meetingContactsSearchPlaceholder: 'Termine suchen appointment',
    externalResources: 'Ihr Programmplan',
    badge: 'IHR E-TICKET',
    noNetwork: "Sie sind nicht mit dem Internet verbunden",
    metContacts: 'Gefundene Kontakte',
    contactsSearchPlaceholder: 'Suche unter Ihren Kontakten',
    cantRemoveItemFromFavorites: 'Sie können Elemente, die mit Ihrem Profil verknüpft sind, nicht aus den Favoriten entfernen.'
  },
  appointment: {
    button: 'Vermittlung',
    setProfileFirst: 'Füllen Sie zunächst Ihr Profil aus, um diese Funktion nutzen zu können.',
    sent: function sent(formatedDate, email, phone) {
      return "Anfrage versendet ".concat(formatedDate, ". Der Empf\xE4nger antwortet Ihnen per E-Mail an die Adresse ").concat(email).concat(phone ? " oder per Telefon unter ".concat(phone) : '', ".");
    },
    errors: {
      GENERIC: "Die Kontaktanfrage konnte nicht gesendet werden.",
      INVALID_REQUEST: "Beim Senden der Kontaktanfrage ist ein Fehler aufgetreten.",
      EXHIBITOR_NOT_FOUND: "Beim Senden der Kontaktanfrage ist ein Fehler aufgetreten.",
      EXHIBITOR_HAS_NO_CONTACT_EMAIL: "Beim Senden der Kontaktanfrage ist ein Fehler aufgetreten.",
      ALREADY_SENT: 'Sie haben diesem Aussteller bereits eine Kontaktanfrage gesendet.'
    }
  },
  networking: {
    participantsFetchError: 'Fehler beim Abrufen der Kontakte'
  },
  synchroFavs: {
    codePlaceholder: 'Code eingeben',
    favoritesPageNoticeUnauthorizedMode: 'Sie können Ihre Favoriten zwischen Ihren verschiedenen Websitzungen und der mobilen App synchronisieren. Klicken Sie dazu auf',
    favoritesPageNoticeExtraUnauthorizedMode: 'Ihre Favoritendaten werden weder für irgendeinen Zweck genutzt noch an Dritte weitergegeben. Sie können die Synchronisation jederzeit durch klicken der folgenden Schaltfläche anhalten',
    favoritesPageNotice: 'Nach der Authentifizierung werden Ihre Favoriten zwischen Websitzungen und der mobilen App synchronisiert.',
    logoutConfirm: 'Ihre Favoriten werden auf diesem Gerät nicht mehr synchronisiert. Wollen Sie diese lokal speichern oder löschen ?',
    logoutConfirmUnauthorizedMode: 'Die Synchronisation auf diesem Gerät wird angehalten. Möchten Sie Ihre Favoriten lokal speichern oder löschen ?',
    status: {
      ongoing: 'Synchronisierung der Favoriten',
      failed: 'Ihre Favoriten sind nicht synchronisiert. Überprüfen Sie Ihre Datenverbindung',
      success: 'Ihre Favoriten sind synchronisiert'
    },
    haveCode: "Ich habe einen Code",
    noCode: "ich habe keinen Code",
    syncCode: 'Synchronisationscode',
    localSyncCode: {
      singular: 'Synchronisationscode verwendet',
      plural: 'Synchronisationscodes verwendet'
    },
    enterCodeNotice: 'Geben Sie Ihren Code ein, um Ihre Favoriten zu synchronisieren',
    codeCreatedNotice: 'Der folgende Code wurde Ihnen zugewiesen. Klicken Sie auf OK, um Ihre Favoriten auf diesem Gerät zu synchronisieren',
    invalidCode: 'Ungültiger Code',
    loadingCode: 'Laden des Codes ...',
    comfirmDisableSynchro: 'Möchten Sie die Synchronisierung Ihrer Favoriten anhalten ?',
    favoritesPageNoticeCodeSynchronised: 'Synchronisiert mit Code',
    onlyDigits: 'Nur Zahlen'
  },
  poll: {
    listTile: 'Verfügbare Umfragen',
    noPolls: 'Keine Umfrage verfügbar',
    sessionTitle: 'Session Rating',
    sessionCodeError: 'UNGÜLTIGER CODE',
    fillTheFields: "Bitte geben Sie Ihren Code ein",
    code: 'code',
    enterCode: "Bitte geben Sie den Sitzungscode ein",
    pleaseRate: "Bitte geben Sie eine Bewertung ab",
    back: 'Zurück',
    next: 'Weiter',
    commentPlaceholder: 'Ihr Kommentar',
    thank: 'Danke',
    submit: 'Übermitteln',
    submitted: 'Bereits übermittelt',
    done: 'Beendet',
    noNetwork: 'Netzwerk nicht verfügbar. Sie können abbrechen und die Übermittlung später durchführen. Ihre Antworten wurden gespeichert.',
    willOpen: 'Öffnung der Umfrage am',
    willClose: 'Schließung der Umfrage am',
    isClosed: 'Diese Umfrage ist geschlossen',
    requireAuthentication: 'Bitte authentifizieren Sie sich.',
    started: 'Gestartet',
    notStarted: 'Nicht gestartet',
    serverError: 'Serverfehler. Sie können später stornieren und einreichen. Ihre Antworten wurden gespeichert.'
  },
  docunit: {
    documents: 'Dokumente',
    document: 'Dokument',
    contacts: 'Kontakt',
    presskit: 'Pressefach'
  },
  filterDialog: {
    title: 'Filtern',
    clear: 'Zurücksetzen'
  },
  klipsoLeads: {
    title: 'Klipso Leads',
    androidScannerText: 'QR-Code in den dafür vorgesehenen Bereich bewegen',
    scanError: 'Der Scan ist fehlgeschlagen',
    scanDenied: "Zugriff auf die Kamera verweigert.",
    disclaimer: "<p>Als Nutzer dieser App sind Sie verpflichtet, Ihrem Kontakt mitzuteilen, dass: </p><ul><li>Seine Einwilligung mit seinem Einverständnis verbunden ist, seinen Ausweis scannen zu lassen.</li><li>Alle bei der Registrierung zur Veranstaltung zur Verfügung gestellten Daten (Kontaktdaten, Tätigkeit, Funktion usw.) an Sie weitergegeben werden können (ohne Anmeldedaten und Passwort).</ul><p>Sie sich verpflichten, jedem Ihrer Kontakte die Möglichkeit zu geben, seine Daten zu berichtigen oder zu löschen, sobald Sie Zugriff darauf haben. </p><p>Sie sich verpflichten, die Daten Ihrer Kontakte, sobald Sie Zugriff darauf haben, vertraulich zu behandeln.</p>",
    accept: "ICH STIMME ZU",
    validate: 'BESTÄTIGEN',
    back: 'Zurück',
    invalidLicence: 'Ungültige Lizenz',
    maxLicenceQuotaReached: "Ihr Benutzerkontingent ist erreicht",
    licenceScanAttemptOfCryptedBadge: 'Ungültiger Lizenz-QR-Code',
    emptyLicenceOrUsername: "Bitte geben Sie Ihren Benutzernamen und Ihre Lizenz ein",
    lsEmptyLicenceAndUsername: "Bitte geben Sie Ihren Benutzernamen und Ihre Lizenz ein",
    lsEmptyLicence: 'Bitte geben Sie Ihre Lizenz ein',
    lsEmptyUsername: "Bitte geben Sie Ihren Nutzernamen ein",
    alphanumericCharWarning: 'Nur alphanumerische Zeichen sind erlaubt',
    licenceScreenFields: {
      licence: 'Lizenzschlüssel',
      userName: 'Geben Sie Namen und Anrede an'
    },
    placeholders: {
      userName: 'Nachname Vorname'
    },
    extendedMenuScreenFields: {
      licence: 'Lizenzschlüssel',
      userName: "Nutzername"
    },
    allContactsSync: ' alle synchronisiert',
    contactsNotSync: {
      singular: 'nicht synchronisierter Kontakt',
      plural: ' nicht synchronisierte Kontakte'
    },
    sortByDate: 'Nach Datum sortieren',
    sortByCompany: 'Nach Unternehmen sortieren',
    listEmpty: "Noch kein Kontakt",
    disabledScanButtonMessage: 'Die Messe ist beendet.',
    invalidVisitorBadge: 'Ungültiger Ausweis',
    close: 'schließen',
    cancel: 'Abbrechen',
    save: 'Speichern',
    saveContactsError: function saveContactsError(count) {
      return count < 2 ? "".concat(count, " Kontakt konnte nicht gespeichert werden :") : "".concat(count, " Kontakte konnten nicht gespeichert werden :");
    },
    formTitle: 'c',
    formNotifMandatoryFieldsToSet: "Pflichtfelder wurden nicht ausgefüllt.",
    historyTitle: 'Verlauf',
    searchPlaceholder: 'Vorname, Nachname, Unternehmen, e-mail…',
    ongoingSync: 'Synchronisierung läuft …',
    resetLicence: 'Lizenz zurücksetzen / ändern',
    resetLicenceConfirmTitle: 'ACHTUNG  ⚠️',
    resetLicenceWithNonSyncContacts: 'Einige Kontakte sind nicht synchronisiert. Wenn Sie auf OK klicken, gehen diese dauerhaft verloren.',
    resetLicenceConfirm: 'Wenn Sie auf OK klicken, wird Klipso Leads zurückgesetzt und die gescannten und auf Ihrem Smartphone gespeicherten Kontakte werden gelöscht. Diese sind jedoch bereits in Ihrem Webspace Aussteller / Ausweisleser gespeichert.<br>Wenn Sie nach dem Zurücksetzen dieselbe Lizenz erneut eingeben, werden Sie als neuer Nutzer erfasst und wenn Ihr Benutzerkontingent erreicht ist, werden Sie abgelehnt.',
    exportContacts: 'Meine Kontakte exportieren',
    tos: 'AGB',
    noAuthorization: "Ihr Benutzerkontingent ist erreicht"
  },
  contacts: {
    scanError: 'Der Scan ist fehlgeschlagen',
    scanDenied: "Zugriff auf die Kamera verweigert.",
    qrCodeUnHnadled: 'Zugriff auf die Kamera verweigert.',
    disclaimer: "<p>Mit diesem Ausweisleser können Sie den Ausweis eines anderen Teilnehmers scannen, um dessen Kontaktinformation vorauszufüllen, in Ihrer App zu speichern (Mein Profil / Registerkarte Meine Kontakte) und in die Kontakte Ihres Smartphones zu exportieren.</p><p>Bevor Sie den Ausweis einer Person scannen, müssen Sie diese um Erlaubnis bitten.</p><p>Bei den vorausgefüllten Informationen handelt es sich um den Namen, Vornamen, die Organisation und die Funktion der Person (sofern verfügbar). Um die E-Mail-Adresse oder die Telefonnummer zu erhalten, fragen Sie die Person und fügen Sie diese hinzu.</p><p>Bevor Sie ein Foto von einer Person aufnehmen, müssen Sie diese um Erlaubnis bitten</p>",
    disclaimerOk: "ICH HABE VERSTANDEN",
    disclaimerNOk: 'Abbrechen',
    loginNeeded: 'Für die Verwendung des Ausweislesers ist eine Authentifizierung erforderlich',
    contactExist: 'Dieser Kontakt befindet sich bereits in Ihren Kontakten, möchten Sie ihn aktualisieren ?',
    photoPermission: 'Bitten Sie die Person um Erlaubnis, bevor Sie ein Bild aufnehmen',
    form: {
      title: 'Kontakte',
      subTitle: "Bitte überprüfen und vervollständigen Sie die Informationen Ihres Kontakts vor dem Speichern. Fragen Sie ihn insbesondere nach seiner E-Mail-Adresse. Sie können auch ein Foto von ihm aufnehmen",
      fields: {
        profilePicture: {
          label: 'Profilbild',
          validationMessage: '',
          alertMessage: "Sie haben Ihr Profilbild nicht bestätigt. Sind Sie sicher, dass Sie fortfahren möchten ?"
        },
        firstName: {
          label: 'Vorname'
        },
        lastName: {
          label: 'Nachname'
        },
        organizationName: {
          label: 'Unternehmen '
        },
        role: {
          label: 'Funktion'
        },
        email: {
          label: 'E-mail'
        },
        phone: {
          label: 'Tel. fixe'
        },
        mobilePhone: {
          label: 'Mobil'
        },
        address: {
          label: 'Adresse'
        }
      },
      imageValidation: ''
    }
  },
  searchTaiga: {
    title: 'Suche - ',
    search: 'Suche',
    goToSimpleSearch: 'Zurück zur einfachen Suche',
    goToAdvancedSearch: 'Erweiterte Suche',
    editSearch: 'Suchkriterien ändern',
    emptyParameters: 'Bitte füllen Sie mindestens ein Feld aus',
    fields: {
      search: {
        label: 'Recherche',
        placeholder: 'Nachname, organisation,...'
      },
      profil: {
        label: 'Art der Struktur',
        placeholder: 'Geben Sie die gesuchte Art der Struktur an'
      },
      name: {
        label: 'Nachname',
        placeholder: 'Bitte geben Sie Nachname'
      },
      company: {
        label: 'Société/Organisme',
        placeholder: 'Société/Organisme'
      },
      role: {
        label: 'Funktion',
        placeholder: 'Geben Sie die gesuchte Funktion an'
      },
      service: {
        label: 'Dienstleistung/Funktion',
        placeholder: 'Geben Sie die gesuchte Dienstleistung an'
      },
      centerinterest: {
        label: "Interessen",
        placeholder: "Geben Sie die gesuchten Interessen an"
      }
    },
    fieldToggle: {},
    askAccuracy: 'Es gibt mehr als #number# Ergebnisse. Bitte verfeinern Sie Ihre Suche',
    allCheckboxLabel: 'Alles auswählen'
  },
  formModal: {
    cancel: 'stornieren',
    send: 'Send'
  },
  permissions: {
    goSettingModalTitle: "Der Zugriff auf die Kamera wurde verweigert",
    goSettingModalMessage: 'Möchten Sie dies in den Einstellungen ändern ?',
    goSettingModalOk: 'Einstellungen',
    goSettingModalCancel: 'Abbrechen'
  },
  localNotif: {
    conf: "Die Konferenz  ",
    begin: " beginnt in",
    mins: "Minuten",
    dismiss: 'Überspringen',
    confirmNotif: 'OK'
  },
  modalPrivacyAgreement: {
    body: "<p> As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As as a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: as a user of this application , you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact que: As a user of this application, you have the obligation to indicate to your contact q ue: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to tell your contact that: </ p ",
    acceptButton: "Genau",
    hint: 'Sie müssen den Vertrag lesen und akzeptieren, bevor Sie fortfahren'
  }
};